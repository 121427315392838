html {
  position: relative;
  min-height: 100%;
  padding-bottom: 62px;
}

.perseids-react-components--footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 62px;
}

.perseids-react-components--footer-nav {
  height: 100%;
  flex-wrap: nowrap;
}

.perseids-react-components--doi {
  height: 30px;
}

.perseids-react-components--twitter {
  height: 30px;
}

.perseids-react-components--octicon {
  height: 30px;
}
