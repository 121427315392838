.perseids-react-components--navbar {
  min-height: 72px;
}

.perseids-react-components--navbar-brand {
  padding-top: 8px!important;
  padding-bottom: 8px!important;
}

.perseids-react-components--navbar-logo-img {
  height: 40px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.54);
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.54);
}
